import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const About = () => (
  <Layout>
    <SEO title="About" />
    <h1>About</h1>
    <h2>Matthew Ortega</h2>
    <p>Born in 1985 in New York City. Lives and works in Queens, New York.</p>
    <p>
      Matthew Ortega is an artist, designer and musician working at the
      intersection of art and technology. He attended Mason Gross School of the
      Arts in the 2000s, where remix culture and questions of authorship
      reemerged as a result of new technologies. He was drawn to the excitement
      about the use of internet detritus and blatant disregard of copyright in
      new works of art. He primarily began to use collage and digital media to
      take part in this open source sense of culture. Beyond the use of
      traditional media such as cut and torn paper, he also worked with video
      and sound by using archival film and youtube clips to create fictional
      narratives. After a brief hiatus working as a digital designer, he
      acquired the skills to start creating custom software to further expand
      his artistic expression. Though he still works with ideas of refuse,
      decay, and distortion that come from remix culture, he also began to
      explore what technology can offer to one’s art practice.
    </p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
);

export default About;
